import React, { Component } from 'react'
import './App.css'
import Navbar from './components/Navbar'
import data from './data'
import SingleProject from './components/SingleProject'
import ProjectPage from './components/ProjectPage'
import PersonalPage from './components/PersonalPage'
import Intro from './components/Intro'
// import {ScrollTo} from "react-scroll-to"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
        scrollY: window.scrollY
      },
      // showingProject: data.projects[2],
      showingProject: null,
      scrollPosition: 0,
      showingNavbar: false,
      showingInfo: false,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.projectClicked = this.projectClicked.bind(this)
    this.removeProject = this.removeProject.bind(this)
    this.updateScrollPosition = this.updateScrollPosition.bind(this)
    this.infoClicked = this.infoClicked.bind(this)
    this.removePersonal = this.removePersonal.bind(this)
    this.iboClicked = this.iboClicked.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions)
    window.addEventListener('scroll', this.updateScrollPosition)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  componentDidUpdate() {
    this.gotToScrollPosition()
  }

  updateWindowDimensions() {
    this.setState({
      dimensions: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    })
  }

  gotToScrollPosition() {
    // window.scrollTo(0, this.state.scrollPosition)
    window.scrollTo({
      left: 0, 
      top:this.state.scrollPosition, 

    })

  }

  updateScrollPosition() {
    if (this.state.showingProject === null) {
      this.setState({
        scrollPosition: window.pageYOffset
      })
    }

    const title = document.getElementById('ibo_ibelings')
    const titlePos = title.getBoundingClientRect().top * -1
    const halfOfTheScreenHeight = this.state.dimensions.height / 2

    this.setState({
      showingNavbar: (titlePos > (halfOfTheScreenHeight))
    })


    /*
    let d = new Date();
    let n = d.getSeconds();
    console.log(n % 2 );

    if (n % 2 == 0 ){
      window.scrollTo({
      left: 0, 
      top : Math.round(this.state.scrollPosition / (this.state.dimensions.height + 10 )) * (this.state.dimensions.height + 10 ), 
      behavior:'smooth'})
    }


    if (this.state.scrollPosition  % this.state.dimensions.height != 0 ) {
      // console.log( this.state.scrollPosition  % this.state.dimensions.height)
      // console.log(Math.round(this.state.scrollPosition / this.state.dimensions.height))    
    }
    */
  }

  projectClicked(project) {
    this.setState({
      showingProject: project,
      showingNavbar: true
    })

  }

  infoClicked() {
    this.setState({
      showingInfo: true
    })
  }

  removeProject() {
    this.setState({
      showingProject: null
    })
    this.gotToScrollPosition(this.state.scrollPosition)
  }

  removePersonal() {
    this.setState({
      showingInfo: false,
    })
}

  iboClicked(){
    this.setState({
      scrollPosition: 0,
      showingInfo: false,
      showingProject:null
    })
  }

  render() {
    const allProjects = (this.state.showingProject == null)
      ? data.projects.map((d) => {
        
        return (
          <SingleProject
            key={d.id}
            dimensions={this.state.dimensions}
            data={d}
            onClick={this.projectClicked} 
            />
        )
      })
      : null

    const project = (this.state.showingProject != null)
      ? <ProjectPage
        showingProject={this.state.showingProject}
        onClick={this.removeProject} />
      : null

    const personalPage = (this.state.showingInfo)
      ? <PersonalPage
        onClick={this.removePersonal}
      />
      : null

    const navbar = (this.state.showingNavbar || this.state.showingProject ) 
      ? <Navbar dimensions={this.state.dimensions} onClick={this.infoClicked} iboClicked={this.iboClicked}/>
      : null

    return (
      <>
        {navbar}
        <Intro />
        {project}
        {personalPage}

        <div
          className="project_scroller" >
          {allProjects}
        </div>

      </>
    )

  }
}

export default App