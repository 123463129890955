import React, { Component } from 'react'

export default class PersonalPage extends Component {

  constructor(props) {
    super()

    this.state = {
      data: props.showingProject
    }

    this.style = {
      backgroundColor: 'white',
      position: 'fixed',
      height: '100vh',
      top: 30,
      overflowY: 'scroll',
      overflowX: 'hidden',
      width: '100vw',
      fontFamily: 'avenir',

    }

    this.paragraphStyle = {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%',
    }

    this.headerStyle = {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%',
    }
    this.yearStyle = {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%',
      fontStyle: 'italic',
    }

    this.titleClicked = this.titleClicked.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }

  titleClicked() {
    this.props.onClick()
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.onClick()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
  }


  render() {
    return (
      <div
        style={this.style}
      >

          {/* Dit is het kruisje */}
          <img 
          alt = "closing cross"
          src="close_cross.png"
          style={{
            width: '20px',
            paddingLeft: '10vw',
            paddingTop: '2vh',
          }}

          onClick = {this.titleClicked}
          />

        <h1
          onClick={this.titleClicked}
          style={{
            fontWeight: '100',
            textAlign: 'center',
          }}
        >
          Ibo Ibelings
        </h1>
       
        <h3 style = {this.headerStyle}>
          Contact
        </h3>

        <p
          style={this.paragraphStyle} >
          mail contact@iboibelings.nl
          <br/>
          phone +31637549872
        </p>


{/* --------- --------- --------- --------- --------- --------- --------- --------- --------- ---------  */}
        <h3 style = {this.headerStyle}>
          Exhibitions
        </h3>

        <div className = "year" style ={this.yearStyle}>2019</div>
          <p style={this.paragraphStyle}>
          Art Machines, Creative Coding Utrecht, Utrecht, NL
          <br/>
          Bright Day, Haarlemmermeer, NL
          <br/>
          Cirque du Data, Utrecht, NL
          <br/>
          Dutch Design Week, Eindhoven, NL
          <br/>
          The Big Draw, Nijmegen, NL
          <br/>
          NXP "Memories", Nijmegen, NL
          <br/>
          ArtEZ Finals, Arnhem, NL  
          </p>

          {/* <div className = "year" style ={this.yearStyle}>2018</div>
          <p style={this.paragraphStyle}>

          </p> */}
          <div className = "year" style ={this.yearStyle} >2017</div>
          <p style={this.paragraphStyle}>
          TodaysArts, Den Haag, NL
          </p>

{/* --------- --------- --------- --------- --------- --------- --------- --------- --------- ---------  */}

        <h3 style = {this.headerStyle}>
          Workshops
        </h3>

        <div className = "year" style ={this.yearStyle}>2019</div>
          <p style={this.paragraphStyle}>
          Meta Marathon, Düsseldorf, DE
          </p>
          {/* <div className = "year" style ={this.yearStyle} >2018</div> */}
          {/* <p style={this.paragraphStyle}> */}

          {/* </p> */}
          {/* <div className = "year" style ={this.yearStyle} >2017</div> */}
          {/* <p style={this.paragraphStyle}> */}

          {/* </p> */}

        <br />
        <br />
        <br />


      </div>

    )
  }
}
