import React from 'react'

export default function Intro() {
  const title_style = {
    margin: 'auto',
    textAlign: 'center',
    fontSize: '10vw',
    fontWeight: 100,
    textTransform: 'capitalize',  
  }

  const title_background = {
    display: 'flex',
    width: '90vw',
    height: '100vh',
    margin: 'auto',
    marginBottom: '10',
  }


  return (
    <div className='title-background' id="ibo_ibelings" style={title_background} >
      <p className="title" style={title_style} >Ibo Ibelings</p>
    </div>
  )
}
