import React, { Component } from 'react'
// import {ScrollTo} from "react-scroll-to"

export default class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      backgroundStyle: {
        top: 0,
        position: 'fixed',
        width: '100vw',
        height: 30,
        // backgroundColor: generateColor(),
        backgroundColor: 'white',
        marginTop: 0,
      },

      paragraphStyle: {
        top: 2,
        paddingLeft: 10,
        position: 'fixed',
        margin: 0,
        textAlign: 'center',
        fontSize: 20,
        fontWeight: '100',
        float: 'left',
      },

      info: {
        top: 2,
        position: 'fixed',
        right: 10,
        textAlign: 'center',
        fontSize: 20,
        fontWeight: '100',
        margin: 0,
      },

      onClick: props.onClick,
      ibo: props.iboClicked
    }
    this.infoClicked = this.infoClicked.bind(this)
    this.iboClicked = this.iboClicked.bind(this)
  }

  infoClicked() {
    this.state.onClick()
  }

  iboClicked(){
    this.state.ibo()
  }

  render() {  


    return (
      < div style={this.state.backgroundStyle} >
        {/* <p
          style={this.state.paragraphStyle}
          onClick = {this.iboClicked}
        >Ibo Ibelings</p> */}


{/* 
      <ScrollTo>
        {({ scroll }) => (
          <a onClick={() => scroll({ x: 0, y: window.scrollY + 1300, smooth:true})}>scroll down </a>
        )}
      </ScrollTo>

          
      |||


      <ScrollTo>
        {({ scroll }) => (
          <a onClick={() => scroll({ x: 0, y: window.scrollY - 1300, smooth:true})}> scroll Up </a>
        )}
      </ScrollTo> */}


        <p style={this.state.info} onClick={this.infoClicked}>info & contact</p>
      </div >
    )
  }
}


/*
function generateColor() {
  return '#' + Math.random().toString(16).substr(-6);
}
*/