import React, { Component } from 'react'

import AwesomeSlider from 'react-awesome-slider'
// eslint-disable-next-line
import AwsSliderStyles from 'react-awesome-slider/src/styles'

export default class ProjectPage extends Component {

  constructor(props) {
    super()

    this.state = {
      data: props.showingProject
    }

    this.style = {
      backgroundColor: 'white',
      position: 'fixed',
      height: '90vh',
      top: 30,
      overflowY: 'auto',
      width: '100vw',
      fontFamily: 'avenir next',
    }

    this.imageStyle = {
      display: 'block',
      margin: 'auto',
      width: '80%',
    }

    this.paragraphStyle = {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%',
    }

    this.titleClicked = this.titleClicked.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }

  titleClicked() {
    this.props.onClick()
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.onClick()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
  }


  render() {


    /*
    const allPorjects = (this.state.showingProject == null)
      ? data.projects.map((d) => {
        
        return (
          <SingleProject
            key={d.id}
            dimensions={this.state.dimensions}
            data={d}
            onClick={this.projectClicked} 
            />
        )
      })
      : null

      */

    // const exhibited = (this.state.data.exhibited != null) 
    //   ? this.state.data.exhibited.map((d) => {
    //     return (d)
    //   }) 
    //   : null

  
    // this.state.date.exhibited.forEach((d)=>{
    //   console.log(d)
    // })

    const exhibited = (
      <p>
        {this.state.data.exhibited.forEach(d => {
          return (d)
        })}
      </p>
    )
    
    const images = this.state.data.images
    let keyId = 0 

    const allImages = images.map((im)=>{
      return (
        <div data-src={`photos/${im}`} key = {keyId++} />
      )
    })



    const slider = (
      <AwesomeSlider className="aws-btn" style={this.imageStyle}>
        {allImages}
      </AwesomeSlider>
    )

    return (

      <div
        style={this.style}
        
      > 
      {/* Dit is het kruisje */}
        <img 
          alt = "closing cross"
          src="close_cross.png"
          style={{
            width: '20px',
            paddingLeft: '10vw',
            paddingTop: '2vh',
          }}

          onClick = {this.titleClicked}
          />

        <h1
          // onClick={this.titleClicked}
          style={{
            fontWeight: '100',
            textAlign: 'center',
            width: "80vw",
            textAligh:"center",
            marginRight:"auto",
            marginLeft:"auto",
            lineHeight: 0.8
          }}

        >
          {this.state.data.title}
        </h1>

        {slider}
        <br />
        <br />
        <br />

        <p
          style={this.paragraphStyle} >
          {this.state.data.description}
        </p>


          
          {exhibited}
          
     

        <br />

      </div >
    )
  }
}
