import React, { Component } from 'react'
// import RightArrow from './RightArrow'

export default class SingleProject extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      projectClicked: props.onClick,
      
      title_style : {
        margin: 'auto',
        textAlign: 'center',
        fontSize: '10vw',
        fontWeight: 100,
        textTransform: 'capitalize',
        color: 'white'
      },
      
      title_background: {
        display: 'flex',
        width: '90vw',
        height: '100vh',
        margin: 'auto',
        backgroundImage: "url(images/"  + props.data.image + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        marginBottom: 20,
      }
    }

    this.ArrowClicked = this.ArrowClicked.bind(this)
  }

  ArrowClicked() {
    this.state.projectClicked(this.state.data)
  }

  render() {
    
    return (
      <div 
        className="title-background"
        style = {this.state.title_background}
        onClick = {this.ArrowClicked}
      >

        <h2
          className="title"
          style = {this.state.title_style}
          onClick={this.ArrowClicked} >
          {this.state.data.title}

        </h2>

        {/* <img
          src='images/RightArrow.png'
          className="Right_Arrow"
          onClick={this.ArrowClicked}
          alt={this.state.id} /> */}

      </div >
    )
  }
}


/*
function generateColor() {
  return '#' + Math.random().toString(16).substr(-6);
}
*/